import { useCallback, useContext, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, Link, Typography } from "@mui/material";
import { useAlert } from "@/hooks/useAlert";
import { pinwheelPalette } from "@/styles/theme";
import { useNavigate } from "react-router-dom";
import { getCaregiverService } from "@/util/api-helper";
import { handleApiError } from "@/util/error-handlers";
import { LoadingButton } from "@mui/lab";
import { MeContext } from "@/context/me";

interface Props {
  refreshPaymentStatus?: (nextPaymentCheckAt: number) => Promise<void>;
}

const NoSubscription = ({refreshPaymentStatus}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { caregiverData, refreshCaregiverData } = useContext(MeContext);
  const alert = useAlert();
  const navigate = useNavigate();

  const handleClick = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const caregiverService = await getCaregiverService();
      if (!caregiverData?.billingEmail) {
        await caregiverService.getCaregiver();
      }
      const caregiversData = await caregiverService.getBillingInfo();
      const subscriptionItems = caregiversData.data.subscriptionItems.filter((subscription) => subscription.quantity > 0);
      if (subscriptionItems.length > 0) {
        // LocalStorage will keep next timestamp for making api call. (Simpler approach for first release)
        // A timer will checking if current time has elapsed the next payment check timestamp before making api call.

        const oneHourInMs = 60 * 60 * 1000; // 1h in milliseconds
        // Run timer in every hour so that within 1 hour of next payment check timestamp we make the call.
        const dayInMs = 24 * oneHourInMs; // 24h in milliseconds
        const now = new Date().getTime();
        const nextPaymentCheckAt = now + dayInMs;
        await refreshPaymentStatus?.(nextPaymentCheckAt);
        refreshCaregiverData?.();
        navigate("/", { replace: true });
      } else {
        alert.error("Subscription not found. Please contact customer care.");
      }
    } catch (err) {
      alert.error("Subscription not found. Please contact customer care.");
      handleApiError(err);
    } finally {
      setIsLoading(false);
    }
  }, [alert, caregiverData?.billingEmail, navigate, refreshCaregiverData, refreshPaymentStatus]);

  return (
    <Dialog
      open={true}
      fullWidth
      fullScreen={true}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Typography variant="h3" my={10}>Active subscription not found.</Typography>
          <Typography mt={2}>If you just bought your child a Pinwheel, check your email for setup instructions.  Otherwise, please contact customer care to get assistance.
          </Typography>
          <Typography mt={2}>
            <Typography>
              Call/Text: <Link
                href="sms:(888) 903-7977"
                target="_blank"
                rel="noopener noreferrer"
              >(888) 903-7977</Link>
            </Typography>
            <Typography>
              Live Chat: <Link href="https://support.pinwheel.com/" target="_blank" rel="noreferrer">support.pinwheel.com</Link>
            </Typography>
            <Typography>
              Email: <Link
                href="mailto:support@pinwheel.com"
                target="_blank"
                rel="noopener noreferrer"
              >support@pinwheel.com</Link>
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: `1px solid ${pinwheelPalette.lightGrey[100]}`,
          display: "block",
          padding: 2,
        }}
      >
        <LoadingButton
          sx={{ float: "right", mt: 1, mx: 1 }}
          variant="text"
          color="primary"
          onClick={handleClick}
          className="action-btn"
          loading={isLoading}
          disabled={isLoading}
        >
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NoSubscription;
