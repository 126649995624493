import theme, { pinwheelPalette } from "@/styles/theme";
import { Typography, Box, Button } from "@mui/material";

interface FeedbackProps {
  feedbackNPSWidget: () => void;
  dismissNPSWidget: () => void;
}
const FeedbackSurvey = ({dismissNPSWidget, feedbackNPSWidget}: FeedbackProps) => {
  return (
    <Box sx={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
      "@media (max-width: 414px)": {
        marginBottom: "10px",
        "p": {
          fontSize: "14px",
          flex: "1 1 0"
        }
      }
    }}>
        <Typography variant="body3" color={pinwheelPalette.black[87]}>How likely are you to recommend Pinwheel to another parent?</Typography>
        <Box sx={{
          "@media (max-width: 414px)": {
            flex: "1 1 0",
            textAlign: "center"
          }
        }}>
          <Button onClick={dismissNPSWidget} variant="text" sx={{marginRight: "15px", color: theme.palette.text.primary}}>Dismiss</Button>
          <Button onClick={feedbackNPSWidget} variant="outlined">Leave feedback</Button>
        </Box>
    </Box>
    )
}

export default FeedbackSurvey
