import { ModifyGroupRequest, UpdateContactRequest } from "@/openapi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getCaregiverService } from "./api-helper";
import { handleApiError } from "./error-handlers";
import ChildContext from "@/context/child";
import { useAppDispatch, useAppSelector } from "@/redux/store/hooks";
import { updateContact as updateContactAction, selectContacts, updateContact } from "@/redux/reducers/contactsReducer/contactsReducer";
import { createGroup, selectGroups, storeGroups } from "@/redux/reducers/groupsReducer/groupsReducer";
import { Contact } from "@/redux/reducers/contactsReducer/contactsModel";
import { IPendingThread } from "./conversations-helper";

export const getKnownAndUnknownThreads = (contacts: Contact[], threads: IPendingThread[]) => {
  const knownThreads: IPendingThread[] = [];
  const unknownThreads: IPendingThread[] = [];

  threads.forEach((t) => {
    const knownContacts = t.contacts.filter((elm) => {
      return contacts.some((contact) => contact.phoneNumber === elm.phoneNumber);
    });
    if (knownContacts.length) {
      knownThreads.push(t);
    } else {
      unknownThreads.push(t);
    }
  });
  
  return { knownThreads, unknownThreads };
};

export const useContactUpdate = () => {
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectContacts);
  const [isLoading, setIsLoading] = useState(false);

  const updateContact = useCallback(
    async (updateContactRequest: UpdateContactRequest) => {
      setIsLoading(true);
      try {
        let request = { ...updateContactRequest };
        const contact = contacts?.find((contact) => contact.id === updateContactRequest.id);
        if (contact) {
          request = { ...contact, ...updateContactRequest };
        }
        const caregiverService = await getCaregiverService();
        const response = await caregiverService.updateContact(request);
        const updatedContact = response.data;
        if (contact) {
          dispatch(updateContactAction(updatedContact));
        }
        return updatedContact;
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [contacts, dispatch],
  );

  return useMemo(() => ({ updateContact, isLoading }), [updateContact, isLoading]);
};

export const useContactGroups = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { child } = useContext(ChildContext);
  const groups = useAppSelector(selectGroups);

  const getContactGroups = useCallback(
    async (childId: string) => {
      setIsLoading(true);
      try {
        const caregiverService = await getCaregiverService();
        const groupsData = (await caregiverService.getChildContactGroups(childId)).data;
        dispatch(storeGroups(groupsData));
        return groupsData;
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (child) {
      if (groups === undefined) {
        getContactGroups(child.id);
      }
    }
  }, [child, groups, getContactGroups]);

  return useMemo(() => ({ getContactGroups, groups, isLoading }), [getContactGroups, groups, isLoading]);
};

export const useCreateContactGroup = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { child } = useContext(ChildContext);

  const createContactGroup = useCallback(
    async (name: string) => {
      if (!child) {
        throw new Error("Child is not set");
      }
      setIsLoading(true);
      try {
        const caregiverService = await getCaregiverService();
        const response = (await caregiverService.createContactGroup({ name: name, childrenId: child.id })).data;
        dispatch(createGroup(response));
        return response;
      } catch (error) {
        handleApiError(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [child, dispatch],
  );

  return useMemo(() => ({ createContactGroup, isLoading }), [createContactGroup, isLoading]);
};

export const useModifyContactGroups = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const contacts = useAppSelector(selectContacts);

  const modifyContactGroups = useCallback(
    async (request: ModifyGroupRequest) => {
      setIsLoading(true);
      try {
        const caregiverService = await getCaregiverService();
        const result = await caregiverService.modifyContactGroups({
          contactId: request.contactId,
          addGroupIds: request.addGroupIds ?? [],
          removeGroupIds: request.removeGroupIds ?? [],
        });

        const contact = contacts?.find((contact) => contact.id === request.contactId);
        if (contact) {
          let groupIds = structuredClone(contact.groupIds);
          if (result.data.groupRequestsAdd && request.addGroupIds) {
            groupIds.push(...request.addGroupIds);
          }
          if (result.data.groupRequestsRemoved && request.removeGroupIds) {
            groupIds = groupIds.filter((id) => !request.removeGroupIds?.includes(id));
          }
          const updatedContact = { ...contact, groupIds: groupIds };
          dispatch(updateContact(updatedContact));
        }
      } catch (error) {
        handleApiError(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [contacts, dispatch],
  );

  return useMemo(() => ({ modifyContactGroups, isLoading }), [modifyContactGroups, isLoading]);
};
