import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrid,
  faCalendarDays,
  faMessageLines,
  faAddressBook,
  faCircleEllipsis,
  faLightbulbOn,
  faMessageQuestion
} from "@fortawesome/pro-light-svg-icons";

import {
  faTabletScreenButton,
  faCircleCheck,
} from "@fortawesome/pro-regular-svg-icons";

import { Box, Menu, MenuItem, ThemeProvider, Typography } from "@mui/material";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import UpvotyWidget, { UpvotyOptions } from "../UpvotyWidget";
import ModalWindow from "../ModalWindow";

import "./mobile-footer.sass";
import { NotificationDot } from "../NotificationDot";
import { useGetContacts } from "@/routes/new-contacts/hooks";
import { SafeListMode } from "@/openapi";
import ChildContext from "@/context/child";
import { useIsWatchChild } from "@/hooks";
import { typographyTheme } from "@/styles/customTheme";

interface Props {
  upvotyWidgetOptions: UpvotyOptions;
  showUpvotyBoard: (s: string) => Promise<void>;
}

interface MenuItemProps {
  icon: React.ReactElement;
  name: string;
  path?: string;
  paths?: string[];
  isModal?: boolean;
  dataCY?: string;
  onClick?: () => void;
}

const Navigation = ({ upvotyWidgetOptions, showUpvotyBoard }: Props) => {
  const { child } = useContext(ChildContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState(location.pathname);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isFeatureWidgetOpen, setFeatureWidgetOpen] = useState(false);
  const mobileFooterRef = useRef<HTMLDivElement | null>(null);
  const { pendingContacts } =useGetContacts();
  const isWatchChild = useIsWatchChild();

  const menuItems: MenuItemProps[] = [
    {
      icon: <FontAwesomeIcon icon={faGrid} size="2x" />,
      name: "Dashboard",
      path: "/dashboard"
    },
    {
      icon: <FontAwesomeIcon icon={faCalendarDays} size="2x" />,
      name: "Schedule",
      path: "/calendar",
      dataCY: "mobileNavSchedule"
    },
    {
      icon: <FontAwesomeIcon icon={faMessageLines} size="2x" />,
      name: "History",
      path: "/phone-history",
      dataCY: "mobileNavPhoneHistory"
    },
    {
      icon: (
        <Box
          sx={{
            display: "block",
            position: "relative",
          }}
        >
          <FontAwesomeIcon icon={faAddressBook} size="2x" />
          {child?.configuration.safelistMode === SafeListMode.Approved && pendingContacts.length > 0 ? (
            <NotificationDot
              sx={{
                position: "absolute",
                top: "-5px",
                right: "-8px",
                fontSize: pendingContacts.length > 9 ? "10px!important" : "12px",
              }}
            >
              {pendingContacts.length > 9 ? "9+" : pendingContacts.length}
            </NotificationDot>
          ) : null}
        </Box>
      ),
      name: "Contacts",
      path: "/contacts",
      dataCY: "mobileNavContacts"
    },
    {
      icon: <FontAwesomeIcon icon={faCircleEllipsis} size="2x" />,
      name: "More",
      paths: ["/apps", "/routines"],
      isModal: true,
      dataCY: "mobileNavMore"
    },
    {
      icon: <FontAwesomeIcon icon={faTabletScreenButton} size="lg" />,
      name: "Apps",
      path: "/apps",
      isModal: true,
      dataCY: "mobileNavApps"
    },
    {
      icon: <FontAwesomeIcon icon={faCircleCheck} size="lg" />,
      name: "Routines",
      path: "/routines",
      isModal: true,
      dataCY: "mobileNavRoutines"
    },
    {
      icon: <FontAwesomeIcon icon={faLightbulbOn} size="lg" />,
      name: "Feature Request",
      onClick: async () => {
        await showUpvotyBoard(`${import.meta.env.VITE_UPVOTY_TOKEN}`);
        setFeatureWidgetOpen(true)
      },
      isModal: true
    },
    {
      icon: <FontAwesomeIcon icon={faMessageQuestion} size="lg" />,
      name: "Help",
      onClick: () => {
        window.open(
          "https://support.pinwheel.com",
          "_blank"
        );
      },
      isModal: true
    }
  ];

  useEffect(() => {
    if (location.pathname) {
      setActiveMenuItem(location.pathname);
    }
  }, [location.pathname]);

  const clickMenuItem = (e: React.MouseEvent<HTMLElement>, link: MenuItemProps) => {
    if (link.path) {
      setActiveMenuItem(link.path);
      navigate(link.path);
    }
    if (link.onClick) {
      link.onClick();
    }
    if (link.isModal) {
      handleClick(e);
    }
  };

  // handle morebutton click for floating menu
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (mobileFooterRef.current) {
      mobileFooterRef.current.addEventListener("touchmove", e => {
        e.preventDefault();
      });
    }
  }, []);

  return (
    <ThemeProvider theme={typographyTheme}>
      <div className="mobile-footer-container" ref={mobileFooterRef}>
        <nav className="mobile-footer" role="navigation" aria-label="main navigation">
          <BottomNavigation showLabels className="mobile-navbar">
            {menuItems.slice(0, 5).map((link, index) => (
              <BottomNavigationAction
                onClick={(e) => {
                  clickMenuItem(e, link);
                }}
                key={index}
                className={`${(link.path && activeMenuItem.includes(link.path)) || (link.paths && link.paths.some(path => activeMenuItem.includes(path)))
                  ? "active"
                  : ""
                  }`}
                label={<Typography variant="caption">{link.name}</Typography>}
                icon={link.icon}
                data-cy={link.dataCY}
              />
            ))}
          </BottomNavigation>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            sx={{
              "& .MuiBackdrop-root": {
                bottom: "3.5rem"
              }
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mb: 2,
                top: "auto !important",
                bottom: 60,
                px: 1.5,
                py: .7,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {menuItems.slice(5, 11).map((link, index) => (
              (isWatchChild && (link.path === "/apps" || link.path === "/routines")) ? null : <MenuItem
                key={index}
                onClick={(e) => clickMenuItem(e, link)}
                data-cy={link.dataCY}
                sx={{ alignItems: "center", "& > .menuIcon": { width: "20px" }, my: .5 }}
              >
                <span className="menuIcon">{link.icon}</span><Typography variant="body1" ml={1.5} pr={4}>{link.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <ModalWindow
            headerText="Feature Request"
            showModal={isFeatureWidgetOpen}
            setShowModal={setFeatureWidgetOpen}
            renderBody={() => <UpvotyWidget options={upvotyWidgetOptions} />}
            isLarge
            cancelButtonText="Close"
          />
        </nav>
      </div>
    </ThemeProvider>
  );
};
export default Navigation;
