import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/pro-light-svg-icons";
import Logo from "../../components/Logo";
import { pinwheelPalette } from "@/styles/theme.ts";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { PaymentStatus } from "@/openapi/models/payment-status.ts";
import { PaymentStatusResponse } from "@/openapi";
import LoadingButton from "@mui/lab/LoadingButton";
import { APIContext } from "../../context/api";
import { handleApiError } from "@/util/error-handlers.ts";
import { useAlert } from "@/hooks/useAlert";

interface Props {
  paymentStatusResponse: PaymentStatusResponse | undefined;
  setPaymentStatusResponse: Dispatch<SetStateAction<PaymentStatusResponse | undefined>>;
}

export const UnpaidSubscription = ({ paymentStatusResponse, setPaymentStatusResponse }: Props) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const alert = useAlert();
  const { getCaregiverService } = useContext(APIContext);

  const getPaymentStatus = async () => {
    try {
      const caregiverService = await getCaregiverService();
      return (await caregiverService.getSubscriptionPaymentStatus()).data;
    } catch(error) {
      handleApiError(error);
    }
  };

  const handleUnlock = async () => {
    setLoading(true);
    const paymentStatusCheckResult = await getPaymentStatus();
    setPaymentStatusResponse(paymentStatusCheckResult);
    setLoading(false);
    if (paymentStatusCheckResult && paymentStatusCheckResult.paymentStatus === PaymentStatus.UnpaidSubscription) {
      alert.error("To unlock your account, please update your payment method first.");
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} sm={9} md={8} lg={6}>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ mb: 2 }}>
            <Logo />
          </Box>
          <Typography variant={isXs ? "h4" : "h3"} component="h1" sx={{ mb: 2 }}>
            Update your payment method
          </Typography>
          <Typography sx={{ color: pinwheelPalette.grey[50], mb: 3 }}>
            Uh oh! It looks like your last subscription payment failed. To continue using the Caregiver Portal, please complete the
            following actions:
          </Typography>
        </Box>
        <Box className="pageMainContent">
          {paymentStatusResponse?.invoiceUrl && (
            <>
              <Box sx={{ mb: 6 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  Instructions
                </Typography>
                <Box sx={{ ml: 3 }}>
                  <ol>
                    <li>
                      <a
                        style={{ textDecoration: "underline", color: pinwheelPalette.purple[100] }}
                        href={paymentStatusResponse.invoiceUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Use this link
                      </a>{" "}
                      to update your payment method.
                    </li>
                    <li>Once you have updated your payment method, tap the &quot;Unlock My Account&quot; button.</li>
                  </ol>
                </Box>
              </Box>

              <Box sx={{ textAlign: "center" }}>
                {loading ? (
                  <LoadingButton loading={loading} loadingPosition="start" startIcon={<></>} variant="contained" sx={{ pl: 5 }}>
                    <span>Unlock My Account</span>
                  </LoadingButton>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faUnlock} className="fa-icons" />}
                    sx={{
                      color: pinwheelPalette.red[100],
                      borderColor: pinwheelPalette.red[100],
                      "&:hover": { borderColor: pinwheelPalette.red[100] }
                    }}
                    onClick={() => handleUnlock()}
                  >
                    Unlock My Account
                  </Button>
                )}
              </Box>
            </>
          )}

          {!paymentStatusResponse?.invoiceUrl && (
            <>
              <Alert severity="warning" sx={{ alignItems: "center" }}>
                We are unable to generate your payment link. To update your payment method, please reach out to Customer Care via{" "}
                <a href="https://support.pinwheel.com/hc/en-us" target="_blank" rel="noreferrer">
                  live chat
                </a>{" "}
                or <a href="tel:8889037977">(888) 903-7977</a>.
              </Alert>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
