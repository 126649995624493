import React from "react";
import { CaregiverApi } from "../openapi";
import { getDeviceDataById, getCaregiverService } from "../util/api-helper";

interface CtxInterface {
  getDeviceDataById: (deviceId: string) => Promise<Response>;
  getCaregiverService: () => Promise<CaregiverApi>;
}
const contextValues: CtxInterface = {
  getDeviceDataById,
  getCaregiverService,
};
export const APIContext = React.createContext(contextValues);

export const APIProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  return <APIContext.Provider value={contextValues}>{props.children}</APIContext.Provider>;
};

export default APIProvider;
