/* tslint:disable */
/* eslint-disable */
/**
 * Pinwheel - Main Service API
 * Pinwheel - Main Service API
 *
 * The version of the OpenAPI document: 2.54.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum NotificationObjectCompletedAction {
    Approved = 'Approved',
    Rejected = 'Rejected',
    Reviewed = 'Reviewed',
    Proposed = 'Proposed',
    Deleted = 'Deleted',
    Uninstalled = 'Uninstalled',
    Cancelled = 'Cancelled',
    PaymentMethodUpdated = 'PaymentMethodUpdated'
}


