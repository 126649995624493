import { alpha, createTheme } from "@mui/material/styles";

// to use => pinwheelPalette.color[hue] => color
// MUI uses a hue value between 900 and 0
// Pinwheel hue represents perceived brightness between 100 and 0
export const pinwheelPalette = {
  red: {
    100: "#eb5757",
    50: "#E35839",
  },
  orange: {
    100: "#E97814",
    5: "#FDF4EC",
  },
  yellow: {
    100: "#F8C923",
  },
  green: {
    100: "#37b954",
  },
  aqua: {
    100: "#00b894",
  },
  blue: {
    100: "#0055D3",
    50: "#0085E3",
  },
  linkBlue: {
    100: "#0096FF",
  },
  darkBlue: {
    100: "#19233e",
  },
  lightBlue: {
    50: "#E8F5FF",
  },
  purple: {
    100: "#573AE2",
    50: "#9a94df",
    5: "#f6f6fd",
  },
  black: {
    100: "#000000",
    87: alpha("#000000", 0.87),
    75: alpha("#000000", 0.75),
    60: alpha("#000000", 0.60),
    55: alpha("#000000", 0.55),
    50: "#252122",
    3: alpha("#000000", 0.03),
    8: alpha("#000000", 0.08),
  },
  white: {
    100: "#ffffff",
    50: "#AFABAC",
  },
  grey: {
    100: "#5a5f77",
    50: "#b7bbc4",
    5: "#b7bcd7",
  },
  lightGrey: {
    100: "#EEF0F4",
    50: "#6E768A",
  },
};

/*
 *  This is the global theme that affects the entire application
 *  It is used by the MUI components
 *  We should aim to change it as little as possible
 *
 *  When setting a color, we should use the pinwheelPalette object
 *  and avoid setting values other than main unless we know what we are doing
 *  It will guess the correct text color based on the main value
 */

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 769,
      lg: 1024,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: pinwheelPalette.purple[100],
    },
    secondary: {
      main: pinwheelPalette.yellow[100],
    },
    error: {
      main: pinwheelPalette.red[100],
    },
    info: {
      main: pinwheelPalette.blue[100],
    },
    success: {
      main: pinwheelPalette.green[100],
    },
    warning: {
      main: pinwheelPalette.orange[100],
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "capitalize",
    },
    h1: {
      fontSize: "2em",
      color: pinwheelPalette.darkBlue[100],
    },
    h2: {
      fontSize: "1.75em",
      color: pinwheelPalette.darkBlue[100],
    },
    h3: {
      fontSize: "1.5em",
      color: pinwheelPalette.darkBlue[100],
    },
    h4: {
      fontSize: "1.25em",
      color: pinwheelPalette.darkBlue[100],
    },
    h5: {
      fontSize: "1em",
      color: pinwheelPalette.darkBlue[100],
    },
    h6: {
      fontSize: "1em",
      color: pinwheelPalette.darkBlue[100],
    },
    subtitle1: {
      fontSize: "1.5em",
      color: pinwheelPalette.darkBlue[100],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        sizeSmall: {
          borderRadius: 4,
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow and border have a hardcoded value temporarily until design is finalized
          boxShadow: "none",
          border: "1px solid #e6e6e6",
          padding: "1rem",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // this is the backdrop that appears behind the modal
          // the "B3" is the 70% opacity added to the end of the color hex
          backgroundColor: pinwheelPalette.grey[50] + "B3",
        },
      },
    },
  },
});
