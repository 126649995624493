import { LoadingButton } from "@mui/lab";
import { Card, Link, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { getCaregiverService } from "@/util/api-helper.ts";
import { handleApiError } from "@/util/error-handlers.ts";
import { getReactNativeOS, isValidEmail } from "@/util/helper.ts";
import { ErrorCodes } from "@/util/error-codes.enum.ts";
import { pinwheelPalette } from "@/styles/theme.ts";
import Logo from "../../components/Logo";
import { useAlert } from "@/hooks/useAlert";
import NoSubscriptionModal from "@/components/NoSubscriptionModal";

interface Props {
  refreshCaregiverData: () => void;
  refreshPaymentStatus?: (nextPaymentCheckAt: number) => Promise<void>;
}

const reactNativeOsName = getReactNativeOS();

export const LinkBilling = ({ refreshCaregiverData, refreshPaymentStatus }: Props) => {
  const alert = useAlert();
  const [code, setCode] = useState("");
  const [submittingBillingEmail, setSubmittingBillingEmail] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState<{ message?: string } | undefined>(undefined);
  const [billingEmail, setBillingEmail] = useState("");
  const [showVerificationScreen, setShowVerificationScreen] = useState(false);


  const handleResendVerification = async (email: string) => {
    try {
      setSubmittingBillingEmail(true);
      const response = await sendEmailVerification(email);
      if (response.errorCode === ErrorCodes.EmailClaimFailedToDeliverCode) {
        alert.error("Something went wrong. Reload to try again or contact Customer Care.");
      }
      if (response.errorCode === ErrorCodes.EmailClaimTooManyAttemptsToSendCode) {
        setShowVerificationScreen(false);
        setVerificationResponse({message: "Too many attempts to generate code. Please try again in a few minutes."});
      }

    } catch (err) {
      handleApiError(err);
    } finally {
      setSubmittingBillingEmail(false);
    }
  }
  const sendEmailVerification = async (email: string) => {
    const caregiverService = await getCaregiverService();
    const claimBillingEmailRequest = {
      email: email
    };
    return (await caregiverService.claimBillingEmail(claimBillingEmailRequest)).data;
  }
  const handleBillingEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = billingEmail.trim();

    if (!isValidEmail(email)) {
      setVerificationResponse(undefined);
      return;
    }

    try {
      setSubmittingBillingEmail(true);
      const response = await sendEmailVerification(email);
      if (response.errorCode === ErrorCodes.EmailClaimFailedToDeliverCode) {
        alert.error("Something went wrong. Reload to try again or contact Customer Care.");
      }
      setSubmittingBillingEmail(false);


      if (response.saved) {
        setShowVerificationScreen(true);
        setVerificationResponse(undefined);
      } else {
        let errorMessage;
        switch (response.errorCode) {
          case ErrorCodes.EmailClaimInvalidEmail:
            errorMessage = "The email you entered does not match our records. Please try another email or reach out to Customer Care at 888-903-7977 for assistance.";
            break;
          case ErrorCodes.EmailClaimTooManyAttemptsToSendCode:
            errorMessage = "Too many attempts to generate code. Please try again in a few minutes.";
            break;
          case ErrorCodes.EmailClaimTooManyAttemptsToVerifyCode:
            errorMessage = "Too many failed attempts to verify code. Please try again in a few minutes.";
            break;
          case ErrorCodes.EmailClaimInvalidCode:
            errorMessage = "Invalid verification code. Please try again.";
            break;
          default:
            errorMessage = "Something went wrong. Reload to try again or contact Customer Care.";
        }
        setVerificationResponse({ message: errorMessage });
      }
    } catch (err) {
      handleApiError(err);
    }
  }

  const handleBillingEmailCodeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setSubmittingBillingEmail(true);
      const caregiverService = await getCaregiverService();

      const verifyBillingEmailRequest = {
        code: String(code).trim()
      };

      if (verifyBillingEmailRequest.code.length < 1) {
        setVerificationResponse({ message: "Please check your email for a security code so we can verify you own the email address." });
        return;
      }

      const response = (await caregiverService.verifyBillingEmail(verifyBillingEmailRequest)).data;
      if (response.errorCode === ErrorCodes.EmailClaimInvalidEmail) {
        setShowVerificationScreen(false);
      }

      if (response.verified) {
        refreshCaregiverData();
      } else {
        let errorMessage;
        switch (response.errorCode) {
          case ErrorCodes.EmailClaimInvalidEmail:
            errorMessage = "The email you entered does not match our records. Please try another email or reach out to Customer Care at 888-903-7977 for assistance.";
            break;
          case ErrorCodes.EmailClaimTooManyAttemptsToSendCode:
            errorMessage = "Too many attempts to generate code. Please try again in a few minutes.";
            break;
          case ErrorCodes.EmailClaimTooManyAttemptsToVerifyCode:
            errorMessage = "Too many failed attempts to verify code. Please try again in a few minutes.";
            break;
          case ErrorCodes.EmailClaimInvalidCode:
            errorMessage = "Invalid verification code. Please try again.";
            break;
          default:
            errorMessage = "Something went wrong. Reload to try again or contact Customer Care.";
        }
  
        setVerificationResponse({ message: errorMessage });
        setSubmittingBillingEmail(false);
        setCode("");
      }
    } catch (err) {
      handleApiError(err);
    }
  };

  if (reactNativeOsName === "ios") {
    return <NoSubscriptionModal refreshPaymentStatus={refreshPaymentStatus} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center"
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "400px",
          },
        }}
      >
        <Logo />
        <Card sx={{
          my: 2,
          "&.link-billing-page": {
            border: 0,
            mt: 0,
            ".MuiTypography-h3": {
              mb: "15px"
            }
          }
        }}
          className="link-billing-page"
        >
          <Typography variant="h3" id="connectBilling">
            Connect your billing email
          </Typography>
          <Typography sx={{ color: pinwheelPalette.grey[50], mb: "40px" }}>Uh oh! Your billing email is not connected to your account. To continue using the Caregiver Portal, please enter the email you provided when you purchased Pinwheel.</Typography>

          {
            !showVerificationScreen ? (
              <form onSubmit={handleBillingEmailSubmit}>
                <Box sx={{ mb: 1.5 }}>
                  <TextField
                    type="email"
                    name="billing_email"
                    id="billing_email"
                    placeholder="you@example.com"
                    value={billingEmail}
                    onChange={e => {
                      setVerificationResponse(undefined);
                      setBillingEmail(e.target.value.trim());
                    }}
                    required={true}
                    fullWidth
                  />
                  {verificationResponse?.message ? (
                    <Typography sx={{ textAlign: "left", color: pinwheelPalette.red[100], mt: "10px" }}>{verificationResponse.message}</Typography>
                  ) : null}
                </Box>
                <div>
                  <LoadingButton variant="contained" loading={submittingBillingEmail} type="submit">
                    Connect billing
                  </LoadingButton>
                </div>
              </form>
            ) : (
              <form className="email-code-verify-form" onSubmit={handleBillingEmailCodeSubmit}>
                <Typography sx={{ textAlign: "left", mb: "10px" }}>Please check your email for a security code so we can verify you own the email address.</Typography>
                <TextField
                  name="billing_email_code"
                  id="billing_email_code"
                  placeholder="XXXXXX"
                  inputProps={{
                    maxlength: "6"
                  }}
                  value={code}
                  style={{ marginTop: 10 }}
                  onChange={e => {
                    setVerificationResponse(undefined);
                    setCode(e.target.value.trim());
                  }}
                  required={true}
                  fullWidth
                />
                {billingEmail && <Typography sx={{
                  fontSize: "12px",
                  textAlign: "left",
                  fontWeight: "600",
                  mt: 1,
                  "a": {
                    color: pinwheelPalette.orange[100],
                    textDecorationColor: pinwheelPalette.orange[100]
                  }
                }}>Didn&apos;t get a code? <Link onClick={() => handleResendVerification(billingEmail)}>Resend it.</Link></Typography>}

                {verificationResponse?.message ? (
                  <Typography sx={{ textAlign: "left", color: pinwheelPalette.red[100], mt: "10px" }}>{verificationResponse.message}</Typography>
                ) : null}
                <Box sx={{ mt: "30px" }}>
                  <LoadingButton variant="contained" loading={submittingBillingEmail} type="submit">
                    Submit
                  </LoadingButton>
                </Box>
              </form>
            )
          }
        </Card>
      </Box>
    </Box>
  );
};
