import React, { useContext, useEffect, useState } from "react";
import { ChildrenContext } from "../../context/children";
import ChildContext from "../../context/child";
import { ChildResponse } from "../../openapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import "./child-selector.sass";
import { Typography, Menu, MenuItem, ListItemText, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme, { pinwheelPalette } from "../../styles/theme";
import { useIsMobile } from "../../hooks";

const ChildSelector = () => {
  const { data: children } = useContext(ChildrenContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { setChild, child } = useContext(ChildContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const getChildByID = (id: string | null) =>
    children!.filter((child: ChildResponse) => child.id === id)[0];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    index: number,
    childId: string,
  ) => {
    setSelectedItemIndex(index);
    setAnchorEl(null);
    setChild(getChildByID(childId));
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (child && children) {
      const currentIndex = children.findIndex((c) => c.id === child.id);
      if (currentIndex !== -1) {
        setSelectedItemIndex(currentIndex);
      }
    }
  }, [child, children]);

  return (
    <ChildContext.Consumer>
      {() => {
        if (!children || !children.length) return null;

        return (
          <>
            <Button
              id={"child-dropdown"}
              aria-controls={open ? "child-dropdown" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faAngleDown} className="fa-icons" />}
              onClick={handleClick}
              size="large"
              className="childSelector"
              fullWidth
              color="inherit"
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: pinwheelPalette.purple[100],
                }}
              >
                {child?.firstName}
              </Typography>
            </Button>

            {!children || !children.length ? null : (
              <Menu
                MenuListProps={{
                  "aria-labelledby": "child-dropdown",
                  role: "listbox",
                }}
                className="child_list_dropdown"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseDropdown}
                sx={{
                  "& .MuiPaper-root": {
                    minWidth: "185px",
                    maxWidth: "80vw",
                  },
                  "& .MuiBackdrop-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {children.map((child: ChildResponse, index: number) => (
                  <MenuItem
                    key={index}
                    sx={{ display: "flex" }}
                    selected={index === selectedItemIndex}
                  >
                    <ListItemText
                      className="childName"
                      sx={{
                        padding: "0 0 0 .75rem",
                        flex: "1",
                        "& .MuiTypography-root": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: theme.palette.text.primary,
                        },
                      }}
                      onClick={(e) => handleMenuItemClick(e, index, child.id)}
                    >
                      {child.firstName}
                    </ListItemText>
                  </MenuItem>
                ))}
                {isMobile && (
                  <MenuItem sx={{ borderTop: `1px solid ${pinwheelPalette.lightGrey[100]}` }}>
                    <ListItemText
                      sx={{
                        padding: "0 0 0 .75rem",
                        flex: "1",
                        color: "#573AE2",
                        "& .MuiTypography-root": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        },
                      }}
                      onClick={() => {
                        navigate(
                          {
                            pathname: "/sign-up-child",
                          },
                          {
                            state: {
                              fromDashboard: true,
                            },
                          },
                        );
                      }}
                    >
                      + Add child
                    </ListItemText>
                  </MenuItem>
                )}
              </Menu>
            )}
          </>
        );
      }}
    </ChildContext.Consumer>
  );
};

export default ChildSelector;
